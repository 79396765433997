import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { IHousedWeb, IHousedWebListItem } from '../../models';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getDadaHousedWeb = (state: State): IHousedWebListItem[] =>
  state.housedWebList;

export const selectHousedWebState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('housedWeb');

export const selectEventsError: MemoizedSelector<object, any> = createSelector(
  selectHousedWebState,
  getError,
);

export const selectHousedWebIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectHousedWebState, getIsLoading);

export const selectHousedWebData: MemoizedSelector<
  object,
  IHousedWebListItem[]
> = createSelector(selectHousedWebState, getDadaHousedWeb);

export const selectHousedWebDetails: MemoizedSelector<
  object,
  IHousedWeb
> = createSelector(
  selectHousedWebState,
  (state: State) => state.housedWebDetails,
);
