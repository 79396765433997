import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { IHousedWeb } from '../../models';
import { HousedWebService } from '../../services/housed-web.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class HousedWebStoreEffects {
  constructor(
    private dataService: HousedWebService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private router: Router,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertiesIds }) =>
        this.dataService.load(propertiesIds).pipe(
          map(({ data }) => fromActions.loadSuccess({ housedWeb: data })),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request, file, groupId }) =>
        this.dataService.update(groupId, request, file).pipe(
          switchMap((response) => {
            this.notifications.updateSuccess('housed_web');
            this.navigateToList();
            let actions: TypedAction<
              | '[Housed Web] Update Success'
              | '[Housed Web] Delete Certificate Request'
            >[] = [fromActions.updateSuccess()];
            if (
              request.web_remote_type === 'webservice' &&
              response.data[0].certificate
            ) {
              actions = [
                ...actions,
                fromActions.deleteCertificateRequest({
                  housedWebGroupId: groupId,
                  withoutNotification: true,
                }),
              ];
            }
            return actions;
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request, file }) =>
        this.dataService.create(request, file).pipe(
          map(() => {
            this.notifications.createSuccess('housed_web');
            this.navigateToList();
            return fromActions.createSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  loadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDetailsRequest),
      switchMap(({ housedWebGroupId }) =>
        this.dataService.loadDetails(housedWebGroupId).pipe(
          map(({ data }: IResponseSuccess<IHousedWeb[]>) => {
            return fromActions.loadDetailsSuccess({
              housedWebDetails: data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadDetailsFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ housedWebGroupId }) =>
        this.dataService.delete(housedWebGroupId).pipe(
          map(() => {
            this.notifications.deleteSuccess('housed_web');
            return fromActions.deleteSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  deleteCertificate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteCertificateRequest),
      switchMap(({ housedWebGroupId, withoutNotification, reloadDetails }) =>
        this.dataService.deleteCertificate(housedWebGroupId).pipe(
          switchMap(() => {
            if (!withoutNotification) {
              this.notifications.deleteSuccess('certificate');
            }
            let actions: TypedAction<
              | '[Housed Web] Delete Certificate Success'
              | '[Housed Web] Load Details Request'
            >[] = [fromActions.deleteCertificateSuccess()];
            if (reloadDetails) {
              actions = [
                ...actions,
                fromActions.loadDetailsRequest({ housedWebGroupId }),
              ];
            }
            return actions;
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteCertificateFailure(error));
          }),
        ),
      ),
    ),
  );

  private navigateToList() {
    this.router.navigate(['/configuration/housed-web']);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
