import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HousedWebStoreEffects } from './effects';
import { housedWebReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('housedWeb', housedWebReducer),
    EffectsModule.forFeature([HousedWebStoreEffects]),
  ],
})
export class HousedWebStoreModule {}
