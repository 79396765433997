import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { generateParamArray } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { IHousedWeb, IHousedWebRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class HousedWebService {
  constructor(private http: HttpClient) {}

  load(propertiesIds: number[]) {
    return this.http.get<IResponseSuccess>(
      `properties/alloggiatiweb?${generateParamArray(
        'property_id',
        propertiesIds,
      )}`,
    );
  }

  loadDetails(housedWebGroupId: number) {
    return this.http.get(`properties/alloggiatiweb/${housedWebGroupId}`);
  }

  delete(housedWebGroupId: number) {
    return this.http.delete(`properties/alloggiatiweb/${housedWebGroupId}`);
  }

  deleteCertificate(housedWebGroupId: number) {
    return this.http.post(
      `properties/alloggiatiweb/${housedWebGroupId}/delete_certificate`,
      {},
    );
  }

  create(request: IHousedWebRequest, files?: any) {
    const formData = objectToFormData(request);

    (files || []).forEach((file: any) => {
      formData.append('certificate', file);
    });

    return this.http.post(`properties/alloggiatiweb`, formData);
  }

  update(groupId: number, request: IHousedWebRequest, files?: any) {
    const formData = objectToFormData(request);

    (files || []).forEach((file: any) => {
      formData.append('certificate', file);
    });

    return this.http.post(
      `properties/alloggiatiweb/${groupId}`,
      formData,
    ) as Observable<IResponseSuccess<IHousedWeb[]>>;
  }
}

function objectToFormData(data: Object, form?: FormData, namespace?: string) {
  const formData: FormData = form || new FormData();

  let formKey: string;

  for (const property in data) {
    if (data.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (
        typeof data[property] === 'object' &&
        !(data[property] instanceof File)
      ) {
        objectToFormData(data[property], formData, formKey);
      } else {
        // if it's a string or a File object
        formData.append(formKey, data[property]);
      }
    }
  }

  return formData;
}
