import { IHousedWeb, IHousedWebListItem } from '../../models';

export interface State {
  isLoading?: boolean;
  housedWebList: IHousedWebListItem[];
  housedWebDetails: IHousedWeb;
  error?: any;
}

export const initialState: State = {
  isLoading: false,
  housedWebList: null,
  housedWebDetails: null,
  error: null,
};
